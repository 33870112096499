import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

// Layouts
import RootLayout from './layouts/rootLayout';

// Pages
import Landing from './pages/landing';
import Pricing from './pages/pricing';
import Contact from './pages/contact';
import Imprint from './pages/imprint';
import NotFoundPage from './pages/nopage';

import i2 from './img/i2.png';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout/>}>
      <Route index element={<Landing />}/>
      <Route path='/imprint' element={<Imprint />}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/pricing' element={<Pricing/>}/>
      <Route path='*' element={<NotFoundPage/>}/>
    </Route>
  )
)

export default function App() {
  return (
    <RouterProvider router={router} />
  )
}