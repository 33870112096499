import './styles/pricing.scss'
import * as Icons from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useState } from 'react'; // Add this line




export default function Pricing() {
    const [activeTab, setActiveTab] = useState('monthly'); // Modify this line

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    
    return (
        <div className='main'>
            <div className='pricing-wrapper'>
                <h1>Preise</h1>
                <span>Meine Coaching Preise</span>
                
                <div className='tab-container'>
                    <div className={`tab ${activeTab === 'monthly' ? 'active' : ''}`} onClick={() => handleTabChange('monthly')}>
                        Monatlich
                    </div>
                    <div className={`tab ${activeTab === 'oneTime' ? 'active' : ''}`} onClick={() => handleTabChange('oneTime')}>
                        Einmalig
                    </div>
                </div>

                <div className={`price-container ${activeTab === 'monthly' ? 'm' : 'o'}`}>
                    {activeTab === 'monthly' && (
                        <>
                            <div className='p-price'>
                                <p>3 Monate</p>
                                <h2>€410</h2>
                                <span>1. Mo 150€, ff. Mo 130 €</span>
                                <div className='p-details'>
                                    <ul> 
                                        <li><Icons.FaCheckSquare/>Vollständige Anamnese</li>
                                        <li><Icons.FaCheckSquare/>Individueller Ernährungsplan</li>
                                        <li><Icons.FaCheckSquare/>Individueller Trainingsplan</li>
                                        <li><Icons.FaCheckSquare/>Technikanalyse</li>
                                        <li><Icons.FaCheckSquare/>Wöchentliche Check-Ins</li>
                                        <li><Icons.FaCheckSquare/>24/7 WhatsApp Kontakt</li>
                                        <li><Icons.FaCheckSquare/>Posingtraining möglich</li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className='p-price'>
                                <p>6 Monate</p>
                                <h2>€750</h2>
                                <span>1. Mo 150€, ff. Mo 120 €</span>
                                <div className='p-details'>
                                    <ul> 
                                        <li><Icons.FaCheckSquare/>Vollständige Anamnese</li>
                                        <li><Icons.FaCheckSquare/>Individueller Ernährungsplan</li>
                                        <li><Icons.FaCheckSquare/>Individueller Trainingsplan</li>
                                        <li><Icons.FaCheckSquare/>Technikanalyse</li>
                                        <li><Icons.FaCheckSquare/>Wöchentliche Check-Ins</li>
                                        <li><Icons.FaCheckSquare/>24/7 WhatsApp Kontakt</li>
                                        <li><Icons.FaCheckSquare/>Posingtraining möglich</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='p-price'>
                                <p>12 Monate</p>
                                <h2>€1360</h2>
                                <span>1. Mo 150€, ff. Mo 110 €</span>
                                <div className='p-details'>
                                    <ul> 
                                        <li><Icons.FaCheckSquare/>Vollständige Anamnese</li>
                                        <li><Icons.FaCheckSquare/>Individueller Ernährungsplan</li>
                                        <li><Icons.FaCheckSquare/>Individueller Trainingsplan</li>
                                        <li><Icons.FaCheckSquare/>Technikanalyse</li>
                                        <li><Icons.FaCheckSquare/>Wöchentliche Check-Ins</li>
                                        <li><Icons.FaCheckSquare/>24/7 WhatsApp Kontakt</li>
                                        <li><Icons.FaCheckSquare/>Posingtraining möglich</li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {activeTab === 'oneTime' && (
                        <>
                            <div className='p-price'>
                                <p>Einmalig 3 Monate</p>
                                <h2>€390</h2>
                                <div className='p-details'>
                                    <ul> 
                                        <li><Icons.FaCheckSquare/>Vollständige Anamnese</li>
                                        <li><Icons.FaCheckSquare/>Individueller Ernährungsplan</li>
                                        <li><Icons.FaCheckSquare/>Individueller Trainingsplan</li>
                                        <li><Icons.FaCheckSquare/>Technikanalyse</li>
                                        <li><Icons.FaCheckSquare/>Wöchentliche Check-Ins</li>
                                        <li><Icons.FaCheckSquare/>24/7 WhatsApp Kontakt</li>
                                        <li><Icons.FaCheckSquare/>Posingtraining möglich</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='p-price'>
                                <p>Einmalig 6 Monate</p>
                                <h2>€699</h2>
                                <div className='p-details'>
                                    <ul> 
                                        <li><Icons.FaCheckSquare/>Vollständige Anamnese</li>
                                        <li><Icons.FaCheckSquare/>Individueller Ernährungsplan</li>
                                        <li><Icons.FaCheckSquare/>Individueller Trainingsplan</li>
                                        <li><Icons.FaCheckSquare/>Technikanalyse</li>
                                        <li><Icons.FaCheckSquare/>Wöchentliche Check-Ins</li>
                                        <li><Icons.FaCheckSquare/>24/7 WhatsApp Kontakt</li>
                                        <li><Icons.FaCheckSquare/>Posingtraining möglich</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='p-price'>
                                <p>Einmalig 12 Monate</p>
                                <h2>€1190</h2>
                                <div className='p-details'>
                                    <ul> 
                                        <li><Icons.FaCheckSquare/>Vollständige Anamnese</li>
                                        <li><Icons.FaCheckSquare/>Individueller Ernährungsplan</li>
                                        <li><Icons.FaCheckSquare/>Individueller Trainingsplan</li>
                                        <li><Icons.FaCheckSquare/>Technikanalyse</li>
                                        <li><Icons.FaCheckSquare/>Wöchentliche Check-Ins</li>
                                        <li><Icons.FaCheckSquare/>24/7 WhatsApp Kontakt</li>
                                        <li><Icons.FaCheckSquare/>Posingtraining möglich</li>
                                    </ul>
                                </div>
                            </div>
                        </>
                        
                    )}
                </div>

                <div className='specifications'>
                    <h1>Spezifikationen</h1>
                    <div className='spec-holder'>
                        <p>Ernährungsplan<span>109</span></p>
                        <p>Trainingsplan<span>69</span></p>
                        <p>Kombi<span>149</span></p>
                    </div>
                    <h1>Personal</h1>
                    <div className='spec-holder'>
                        <p>Consult Call<span>50</span> / 30 Minuten</p>
                        <p>Personal Training<span>120</span> / Session</p>
                    </div>
                </div>

                <div className='start-btn'>
                    <Link to="/contact">Jetzt durchstarten</Link>
                </div>
            </div>
        </div>
    )
}

