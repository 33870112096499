import Navbar from '../navigation/navbar'
import Footer from '../navigation/footer'

import { Outlet } from 'react-router-dom'

export default function RootLayout() {
  return (
    <>
        <Navbar/>

        <main>
            <Outlet/>
        </main>

        <Footer/>        
    </>
  )
}
