import { useState } from 'react';
import './styles/contact.scss'
import emailjs from '@emailjs/browser';




export default function Contact() {
  const [fName, setFName] = useState('');
  const [fEmail, setFEmail] = useState('');
  const [fPhone, setFPhone] = useState('');
  const [fAge, setFAge] = useState('');
  const [fWeight, setFWeight] = useState('');
  const [fSize, setFSize] = useState('');
  const [fPlan, setFPlan] = useState('');
  const [fGoal, setFGoal] = useState('');
  const [fPayment, setFPaymentMethod] = useState('');


  
  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    var templateParams = {
      name: fName,
      email: fEmail,
      phone: fPhone,
      age: fAge,
      weight: fWeight,
      size: fSize,
      plan: fPlan,
      goal: fGoal,
      payment: fPayment
  };

    const from = 'philipp-wocheslander.de'; 
    const to = '015204379807';
    const message = `Customer Data:\n\n Name:  ${fName},\n Email:  ${fEmail},\n Phone:  ${fPhone},\n Age:  ${fAge},\n Weight:  ${fWeight},\n Size:  ${fSize},\n Plan:  ${fPlan},\n Goal:  ${fGoal},\n Payment:  ${fPayment}`;

    console.info(from)
    console.log(message)
    console.info(to)

    emailjs.send("service_fwu8a0r", "template_8ojg9l4",{
      from_name: fName,
      message: message,
    }, "162Wr04puNxK4wwAZ")
    .then((response) => {
      console.log('%c SUCCESS! ' + response.status + response.text, 'color: green');
      alert("Formular abgeschickt!")
   }, (err) => {
      console.log('FAILED... ' + err, 'color: red');
      alert("Eintrag fehlgeschlagen!")
   });

    event.target.reset()
  }

  return (
    <div className='main'>
      <div className='form-wrapper'>
        <h1>Kontaktier mich direkt</h1>
        <form onSubmit={handleFormSubmit}>
          <label>Name *</label>
          <input
            placeholder='Dein Name'
            type='text'
            value={fName}
            onChange={(e) => setFName(e.target.value)}
            required
          />
          <label>Email *</label>
          <input
            placeholder='Email-Adresse'
            type='email'
            value={fEmail}
            onChange={(e) => setFEmail(e.target.value)}
            required
          />
          <label>Phone *</label>
          <input
            placeholder='Telefonnummer'
            type='tel'
            value={fPhone}
            onChange={(e) => setFPhone(e.target.value)}
            required
          />

          <p>Infos über Dich</p>
          <label>Alter *</label>
          <input
            placeholder='Dein Alter'
            type='number'
            value={fAge}
            onChange={(e) => setFAge(e.target.value)}
            required
          />
          <label>Gewicht *</label>
          <input
            placeholder='Dein Gewicht (kg)'
            type='number'
            value={fWeight}
            onChange={(e) => setFWeight(e.target.value)}
            required
          />
          <label>Größe *</label>
          <input
            placeholder='Deine Größe (cm)'
            type='number'
            value={fSize}
            onChange={(e) => setFSize(e.target.value)}
            required
          />

          <label>Dein Ziel *</label>
          <textarea
            value={fGoal}
            onChange={(e) => setFGoal(e.target.value)}
            required
          ></textarea>

          <p>Wähle einen Plan</p>
          <label>Plan *</label>
          <select
            value={fPlan}
            onChange={(e) => setFPlan(e.target.value)}
            required
          >
            <option value="" disabled>Wähle deinen Plan</option>
            <option value="3">3 Monate</option>
            <option value="6">6 Monate</option>
            <option value="12">12 Monate</option>
            <option value="Con Call">Consulting Call</option>
            <option value="Pers Train">Persönliches Training</option>
            <option value="Ern Plan">Ernährungsplan</option>
            <option value="Train Plan">Trainingsplan</option>
            <option value="Train + Ern Plan">Trainings- & Ernährungsplan Kombi</option>
            <option value="unknown">Ich weiß es noch nicht</option>
          </select>

          
          <label>Zahlungsweise</label>
          <select
            value={fPayment}
            onChange={(e) => setFPaymentMethod(e.target.value)}>

            <option value="" disabled>Wähle deinen Zahlungsweg</option>
            <option value="1x Payment">Einmalzahlung</option>
            <option value="Payment / Month">Monatszahlung</option>
          </select>

          <div className='check-copy'>
            <input type='checkbox' required />
            <p>
              Mit Nutzung des Formulars erklärst Du dich mit der Speicherung
              und Verarbeitung Deiner Daten einverstanden.
            </p>
          </div>
          <button type='submit'>Kontakt & Gains gewinnen</button>
        </form>
      </div>
    </div>
  );
}
