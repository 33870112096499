import { Link } from 'react-router-dom';
import './footer.scss'
var logo = require('../Logo/L2.png');

export default function Footer() {
  return (
      <footer>
        <div className='footer-wrapper'>
          <div className='footer-block'>
            <img src={logo} alt='logo' />
          </div>

          <div className='footer-block'>
            <Link to='/contact'>Coaching</Link>
            <Link to='/pricing'>Preise</Link>
            <Link to='/contact'>Kontakt</Link>
            <Link to='/imprint'>Impressum</Link>
          </div>
        </div>

        <div className='copy'>{`@ ${new Date().getFullYear()} - Philipp Wocheslander`}</div>
      </footer>
  )
}
