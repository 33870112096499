import React, { useEffect, useState } from 'react'
import './navbar.scss'
import { NavLink } from 'react-router-dom';

var logo = require('../Logo/L1.png');
var logoSmall = require('../Logo/W1.png');







export default function Navbar() {

    const [sideBarState, setSideBarState ] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const openSideBar = () => {
        setSideBarState(!sideBarState)
    }

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY && window.scrollY >= 200) { // if scroll down and scrolled at least 90px, hide the navbar
          setShow(false); 
        } else { // if scroll up or scrolled less than 90px, show the navbar
          setShow(true);  
        }
      
        // remember current page location to use in the next move
        setLastScrollY(window.scrollY); 
      };

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', controlNavbar);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
          window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    return (
        <nav className={`active ${show && 'hidden'}`}>
            <div className='nav-wrapper'>
                <div className='logo'>
                    <NavLink to='/'>
                        <img src={windowWidth <= 760 ? logoSmall : logo} alt='Logo' />
                    </NavLink>
                </div>

                <div className='links'>
                    <NavLink to='/'>Home</NavLink>
                    <NavLink to='pricing'>Preise</NavLink>
                    <NavLink to='imprint'>Impressum</NavLink>
                </div>

                <div className='start-btn'>
                    <NavLink to='contact'>Kontakt</NavLink>
                </div>


                <div className={`ham-menu ${sideBarState ? 'active' : ''}`} onClick={openSideBar} >
                    <div/>
                    <div/>
                    <div/>
                </div>

                <div className={`sideBar ${sideBarState ? 'open' : ''}`}>
                    <div className='sideBar-wrapper'>
                        <NavLink onClick={openSideBar} to='/'>Home</NavLink>
                        <NavLink onClick={openSideBar} to='pricing'>Preise</NavLink>
                        <NavLink onClick={openSideBar} to='imprint'>Impressum</NavLink>
                    </div>
                </div>
            </div>
        </nav>
    )
}
