import React, { useEffect, useState } from 'react'
import * as Icons from "react-icons/fa";
import './styles/landing.scss'
import { Link } from 'react-router-dom';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import AOS from 'aos';
import 'aos/dist/aos.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';


import before from '../img/before.jpg';
import after from '../img/after.jpg';

import i1 from '../img/i1.jpg';

import j1 from '../img/7.jpg';
import j3 from '../img/8.jpg';
import j5 from '../img/10.jpg';
import j8 from '../img/13.jpg';
import j10 from '../img/15.jpg';
import j11 from '../img/17.jpeg';

import w8 from '../img/Woche-Whatsapp-8.png';
import w3 from '../img/Woches-WA-3.png';
import w2 from '../img/Woches-Whatsapp-2.png';
import w4 from '../img/Woches-Whatsapp-4.png';
import w5 from '../img/Woches-Whatsapp-5.png';
import w6 from '../img/Woches-Whatsapp-6.png';
import w7 from '../img/Woches-Whatsapp-7.png';
import w9 from '../img/Woches-Whatsapp.png';

import h1 from '../img/hantel.jpg';

function AboutWoches() {

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  

  return(
        <div className='beforeAfter-container'>
          <div className={`beforeAfter-grid ${expanded ? 'expanded' : ''}`}>
              <div className='about-wrapper'>
                <div className='about-text'>
                  <h1>Servus!</h1>
                  <p>
                    Schön, dass du es auf meine Website geschafft hast. Ich möchte mich kurz bei dir vorstellen, sodass du dir einen ersten Eindruck von mir und schließlich meinem Coaching machen kannst.
                    <br/><br/>
                    Mein Name ist Philipp Wocheslander, ich bin derzeit 28 Jahre alt und stamme aus dem Landkreis Regensburg. Aber nun zu meiner Geschichte.
                    <br/><br/>
                    Nun - wo fange ich an? Als Jugendlicher spielte ich, wie schließlich fast jeder Junge, Fußball beim lokalen Dorfverein. Leider verletzte ich mich mit 14 Jahren beim Skifahren, weshalb ich knapp 1 1/2 Jahre kein Fußball mehr spielen konnte. In dieser Zeit entdeckte ich so einige Computerspiele und spielte täglich stundenlang. Da ich keinen Sport mehr machte, nahm ich extrem zu und wurde schließlich in der Schule zum Mobbingopfer. 
                    <br/><br/>
                    Mit 17 Jahren und einer selbst durchgeführten Radikaldiät entdeckte ich nach einem Schulwechsel das Fitnessstudio für mich. Ab dem ersten Besuch war ich „addicted“. Ich meldete mich mit meinem Cousin an und wir starteten direkt mit 5 Trainingseinheiten in der Woche. 
                    <br/><br/>
                    Natürlich hatten wir absolut keine Ahnung was wir machten und trainierten nach dem Plan eines älteren Wettkampfathleten, den wir während seines Trainings beobachteten.  Nach kurzer Zeit merkten wir auch die ersten Erfolge, jedoch blieben wir auch an diesen erstmal hängen. 
                    <br/><br/>
                    Also fing ich an mich durch damalige Fitnessgruppen in Facebook sowie deutsch- und englischsprachige YouTubevideos weiterzubilden und die Funktionsweise des menschlichen Körpers kennenzulernen und zu verstehen.
                    <br/><br/>
                    Heute habe ich mich durch Plattformen wie „LiftTheStandard“, Consultcalls mit Topcoaches und dem Lesen von Studien und Lektüren weitergebildet und mir so einiges an Wissen angeeignet. 
                    <br/><br/>
                    Ich möchte nun DIR helfen den Körper, den du dir vorstellst, zu erschaffen. Ganz egal ob du dich für das klassische Bodybuilding oder einen gesunden, athletischen Lifestyle entscheidest - gemeinsam werden wir Schritt für Schritt die Grundlage für einen gesunden und athletischen Körper legen und deinen Traumkörper schaffen. 
                    <br/><br/>
                    Melde dich noch heute für dein kostenloses und unverbindliches Erstgespräch! 
                    <br/><br/>
                    Ich freue mich auf dich!</p>

                </div>
                <a className='cnt-btn' onClick={toggleExpanded}>{expanded? "Weniger Lesen" : "Weiterlesen"}</a>
            </div>


            <div className='exple'>
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src={before} alt="Image one" />}
              itemTwo={<ReactCompareSliderImage src={after} alt="Image two" />}
            />
            </div>
        </div>
    </div>
  )
}



export default function Landing() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="main">
      <div className='bg'>
        <img src='https://img.freepik.com/vektoren-kostenlos/marmorbeschaffenheitshintergrund_125540-356.jpg?w=1480&t=st=1703876872~exp=1703877472~hmac=f8421d8db04cfe605849b8c9fd1f8e5921207b05130e613fc0bb2c5091e93a01'/>
      </div>


      <div className='company-wrapper'>
          <h1>Find your<br/>strength</h1>
      </div>

      <div className='main-wrapper'>
          <div className='reg-wrapper'>
          <h1>Dein individuelles 1:1 Coaching</h1>
          </div>
      </div>


      <AboutWoches/>

      <div className='services-container'>
        <div className='services-wrapper'>
          <div className='services'>

          <div className='service'>
              <div className='service-text'> 
                <h1>Anamnese deines Ist-Zustandes</h1>
                  <p>
                  Als Erstes vereinbaren wir einen Call, indem wir deinen aktuellen Ist-Zustand, etwaige Probleme und deine Ziele ermitteln. Im Anschluss daran erhältst du einen detaillierten Fragebogen. Falls Fragen auftreten, vereinbaren wir erneut einen Call um diese zu klären, sodass im Anschluss daran die Grundlage für eine erfolgreiche Zusammenarbeit entstehen kann! 
                  </p>
              </div>

              <div className='service-img'>
                <img src={j8} alt='service-img'/>
              </div>
            </div>

            <div className='line'/>

            <div className='service'>
              <div className='service-text'> 
                <h1>Individueller Ernährungsplan</h1>

                  <p>
                    Individuell auf dich, deinen Alltag und deine Ziele angepasst. Egal ob Omnivore, vegetarisch oder vegan. 
                  </p>
              </div>

              <div className='service-img'>
                <img src={j10} alt='service-img'/>
              </div>
            </div>

            <div className='line'/>

            <div className='service'>
              <div className='service-text'> 
                <h1>Trainingsplan</h1>

                  <p>
                    Individuell auf dich, deine Stärken und Schwächen, deinen Alltag, deinen Zielen und vor allem deiner Vorlieben angepasst.
                  </p>
              </div>

              <div className='service-img'>
                <img src={j1} alt='service-img'/>
              </div>
            </div>

            <div className='line'/>
                        
            <div className='service'>
              <div className='service-text'> 
                <h1>Wöchentliche Check-Ins</h1>

                  <p>
                    Einmal wöchentlich lädst du in deinen persönlichen GoogleDrive-Ordner deine aktuellen Formbilder hoch. Per What‘s App schickst du mir dein Feedback zur vergangenen Woche und deinen Up & Downs. Wir analysieren gemeinsam deinen Ist-Zustand, lassen gegebenenfalls die Pläne an und finden Lösungen für etwaige Probleme. Dies erhältst du professionell via Video.
                  </p>
              </div>

              <div className='service-img'>
                <img src={j11} alt='service-img'/>
              </div>
            </div>

            <div className='line'/>

            <div className='service'>
              <div className='service-text'> 
                <h1>Technikanalyse</h1>

                  <p>
                    Du kannst deine Übungen zu jeder Zeit filmen und mir per What‘s App zuschicken. Ich analysiere deine Technik und deine Intensität. Anschließend erhältst du ein ausführliches Feedback zu deiner Trainingssession.
                  </p>
              </div>

              <div className='service-img'>
                <img src={j3} alt='service-img'/>
              </div>
            </div>

            <div className='line'/>

            <div className='service'>
              <div className='service-text'> 
                <h1>24/7 WhatsApp Support</h1>

                  <p>
                  Du erhältst meine persönliche WhatsApp Nummer und kannst mich jederzeit bezüglich Fragen o.ä. kontaktieren. Alles schnell, einfach und unkompliziert.
                  </p>
              </div>

              <div className='service-img'>
                <img src={j5} alt='service-img'/>
              </div>
            </div>

          </div>
        </div>
      </div>
      

      <div className='motivation-container' data-aos="fade-up">
        <div className='motivation-speech'>
          <h1>Erreiche deine Ziele</h1>
          <p>Gemeinsam mit Philipp Wocheslander</p>
          <div className='line'/>
          <span>Ich möchte nun DIR helfen den Körper, den du dir vorstellst, zu erschaffen. Ganz egal ob du dich für das klassische Bodybuilding oder einen gesunden, athletischen Lifestyle entscheidest - gemeinsam werden wir Schritt für Schritt die Grundlage für einen gesunden und athletischen Körper legen und deinen Traumkörper schaffen. </span>
        </div>
      </div>

      
      <div className='join-now' data-aos="fade-up">
        <div className='join-now-container'>
            <Link to='pricing'>
                Preise ansehen
            </Link>
            <Link to='contact'>
                Jetzt kontaktieren!
            </Link>
        </div>
      </div>


      <div className='feedback-container'>
        <h1>Feedback von Kunden</h1>
        <Carousel className='carousel-feedback' autoPlay={true} emulateTouch={true} interval={3000} infiniteLoop={true} showArrows={true} stopOnHover={false} showStatus={false} showIndicators={false} showThumbs={false}>
          <div className='feedback-proof'>
            <img src={w8} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w3} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w4}alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w5} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w6} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w7} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w9} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w8} alt='feedback'/>
          </div>

          <div className='feedback-proof'>
            <img src={w2} alt='feedback'/>
          </div>
        </Carousel>
      </div>


      <div className='discount-container'>
        <div className='discount-code' data-aos="fade-up">
          <h1>Maximal bei ESN sparen!</h1>
          <p>Spare mit meinem Code WOCHES maximal bei deinem Einkauf auf ESN.com</p>
          <div className='go-btn'>
            <a href='https://www.esn.com/discount/woches'>Code einlösen</a>
          </div>
        </div>

        <div className='top-products'>
          <div className='products-container'>
            <div className='product'>
              <img src='https://www.esn.com/cdn/shop/files/CollagenPeptides_300g_PeachIcedTeaFlavor_900x1200_shopbild_f7210261-a014-4af6-8351-27f3e04623fb_540x.jpg?v=1703669138' alt='esn-product'/>
              <div className='price'>
                <p>27,90</p>
                <p>25,11</p>
              </div>
            </div>
            
            <div className='product'>
            <img src='https://www.esn.com/cdn/shop/files/DesignerMaizePudding_3000g_NeutralFlavor_Front_900x1200_shopbild_fd332a51-f552-464a-b450-4e8fe4f2148e_540x.jpg?v=1701954263' alt='esn-product'/>
              <div className='price'>
                <p>27,90</p>
                <p>25,11</p>
              </div>
            </div>

            <div className='product'>
            <img src='https://www.esn.com/cdn/shop/files/DesignerWhey_908g_SaltedCaramelFlavor_900x1200_shopbild_de95dc2b-cdf9-498d-8e72-d0852a2644c0_540x.jpg?v=1702472812' alt='esn-product'/>
              <div className='price'>
                <p>27,90</p>
                <p>25,11</p>
              </div>
            </div>
            
            
          </div>

          <h1>Meine empfehlungen</h1>
        </div>
      </div>

      <div className='insta-info'>
        <div className='insta-info-container'>
            <div className='insta-info-pfp'>
              <img src={i1} alt='Insta-PFP'/>
            </div>

            <div className='insta-info-text'>
              <h1>Woches</h1>
              <p>Philipp Wocheslander | Online Lifestyle Coach</p>
              <a href='https://www.instagram.com/woches/' target='_blank'>Folgen</a>
            </div>
          </div>
      </div>
      

      <div className='start-now' data-aos="fade-up">
        <div className='start-now-container'>
          <Icons.FaCheckSquare/>
          <div className='text-wrapper'>
            <h1>Sichere dir jetzt deinen Spot</h1>
            <span>Starte noch heute mit deiner Persönlichen erfolgsstory!</span>
          </div>
          <Link to='contact'>Heute noch durchstarten!</Link>
        </div>
      </div>


    </div>
  )
}
